import React, { useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const NavigationBar = () => {
  
  useEffect(() => {
    const navbarShrink = function () {
      const navbarCollapsible = document.body.querySelector("#mainNav");
      if (!navbarCollapsible) {
        return;
      }
      if (window.scrollY === 0) {
        navbarCollapsible.classList.remove("navbar-shrink");
      } else {
        navbarCollapsible.classList.add("navbar-shrink");
      }
    };

    navbarShrink();

    document.addEventListener("scroll", navbarShrink);

    import('bootstrap').then((bootstrap) => {
      const mainNav = document.body.querySelector("#mainNav");
      if (mainNav) {
        new bootstrap.ScrollSpy(document.body, {
          target: "#mainNav",
          rootMargin: "0px 0px -40%",
        });
      }

      const navbarToggler = document.body.querySelector(".navbar-toggler");
      const responsiveNavItems = [].slice.call(
        document.querySelectorAll("#navbarResponsive .nav-link")
      );
      responsiveNavItems.forEach((responsiveNavItem) => {
        responsiveNavItem.addEventListener("click", () => {
          if (window.getComputedStyle(navbarToggler).display !== "none") {
            navbarToggler.click();
          }
        });
      });
    });

    return () => {
      document.removeEventListener("scroll", navbarShrink);
    };
  }, []);

  return (
    <Navbar collapseOnSelect expand="lg" className="navbar-dark" variant="dark" fixed="top" id="mainNav">
      <Container>
        <Navbar.Brand href="https://greenguardian.cl"><img src="assets/img/logos/logoGreenGuardian.png" alt="..." /></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarResponsive" className="navbar-toggler" />
        <Navbar.Collapse id="navbarResponsive">
          <Nav className="ms-auto py-4 py-lg-0">
            <Nav.Link href="#project">Proyecto</Nav.Link>
            <Nav.Link href="#services">Servicios</Nav.Link>
            <Nav.Link href="#team">Equipo</Nav.Link>
            <Nav.Link href="#contact">Contacto</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
