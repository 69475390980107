import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

const Project = () => {
  useEffect(() => {
    // Código para inicializar componentes de Bootstrap
    import("bootstrap").then((bootstrap) => {
      const modalElementList = [].slice.call(document.querySelectorAll(".modal"));
      modalElementList.forEach((modalEl) => {
        new bootstrap.Modal(modalEl);
      });
    });
  }, []);

  return (
    <section className="page-section" id="project">
      <Container>
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Proyecto</h2>
          <h3 className="section-subheading text-muted">
            GreenGuardian: Solución para la gestión del agua en la agricultura.
          </h3>
        </div>
        <Row>
          <Col lg={6} className="mb-4">
            <div className="project-text">
              <h4 className="text-uppercase">Descripción</h4>
              <p className="text-muted">
                GreenGuardian es una solución integral para la gestión del agua en la agricultura, que busca combinar tecnología avanzada para optimizar el uso del agua, reducir el impacto ambiental y mitigar problemáticas asociadas a la escasez hídrica que vive y vivirá nuestro país en los próximos 30 años.
                <br />
                La propuesta incluye tanto una aplicación móvil como una página web, facilitando el acceso a datos en tiempo real y análisis detallados para mejorar la eficiencia y sostenibilidad de los cultivos.
                <br />
                Al lado, se presenta un prototipo en 3D que visualiza la infraestructura y los sistemas automatizados que componen esta innovadora solución de cultivo hidropónico.
              </p>
            </div>
          </Col>
          <Col lg={6} className="mb-4">
            <div className="project-video">
              <h4 className="text-uppercase">Prototipo</h4>
              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/DhPDiFSHu_M"
                  allowFullScreen
                  title="Video del Proyecto"
                ></iframe>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Project;
