import React from "react";
import { Container, Row, Col } from "react-bootstrap";


const Team = () => {
  return (
    <section className="page-section" id="team">
      <Container>
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Nuestro Equipo</h2>
          <h3 className="section-subheading text-muted">
            Somos un grupo de profesionales comprometidos con la calidad.
          </h3>
        </div>
        <Row>
          <Col lg={4}>
            <div className="team-member">
              <img
                className="mx-auto team-img"
                src="assets/img/team/gonzalo.jpg"
                alt="..."
              />
              <h4>Gonzalo Altamirano</h4>
              <p className="text-muted">Desarrollador Web</p>         
            </div>
          </Col>
          <Col lg={4}>
            <div className="team-member">
              <img
                className="mx-auto team-img"
                src="assets/img/team/duvan.jpg"
                alt="..."
              />
              <h4>Duván Apiolaza</h4>
              <p className="text-muted">Base de Datos</p>
            </div>
          </Col>
          <Col lg={4}>
            <div className="team-member">
              <img
                className="mx-auto team-img"
                src="assets/img/team/juan.jpg"
                alt="..."
              />
              <h4>Juan Marambio</h4>
              <p className="text-muted">Desarrollador Móvil</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <div className="team-member">
              <img
                className="mx-auto team-img"
                src="assets/img/team/marcos.jpg"
                alt="..."
              />
              <h4>Marcos Medina</h4>
              <p className="text-muted">Desarrollador Web</p>
            </div>
          </Col>
          <Col lg={4}>
            <div className="team-member">
              <img
                className="mx-auto team-img"
                src="assets/img/team/mathias.jpg"
                alt="..."
              />
              <h4>Mathias Navarrete</h4>
              <p className="text-muted">Scrum Master</p>
            </div>
          </Col>
          <Col lg={4}>
            <div className="team-member">
              <img
                className="mx-auto team-img"
                src="assets/img/team/tomas.jpg"
                alt="..."
              />
              <h4>Tomás Sureda</h4>
              <p className="text-muted">Desarrollador Móvil</p>
              
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Team;
