import React from 'react';
import NavigationBar from './components/Navbar';
import Masthead from './components/Masthead';
import Services from './components/Services';
import Project from './components/Project';
// import About from './components/About';
import Team from './components/Team';
// import Clients from './components/Clients';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <NavigationBar />
      <Masthead />
      <Project />
      <Services />
      {/* <About /> */}
      <Team />
      {/* <Clients /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
