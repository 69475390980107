import React from 'react';
import { Container, Button } from 'react-bootstrap';

const Masthead = () => {
  return (
    <header className="masthead">
      <Container className="text-center">
        <div className="masthead-subheading">¡Bienvenido al cultivo futurista!</div>
        <div className="masthead-heading text-uppercase">
          <span className="text-white">Green</span><span className="text-green">Guardian</span> es lo que necesitas
        </div>
        <Button className="btn btn-green btn-xl text-uppercase" href="#project">Let's Go!</Button>
      </Container>
    </header>
  );
}

export default Masthead;
