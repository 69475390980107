import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShoppingCart,
  faLaptop,
  faSunPlantWilt,
  faChartSimple,
  faRobot,
  faLock,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

const Services = () => {
  return (
    <section className="page-section bg-light" id="services">
      <Container>
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Servicios</h2>
          <h3 className="section-subheading text-muted">
            Los servicios que ofrecemos son:
          </h3>
        </div>
        <Row className="text-center">
          <Col md={4}>
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon
                icon={faCircle}
                className="fa-stack-2x circle"
              />
              <FontAwesomeIcon
                icon={faShoppingCart}
                className="fa-stack-1x fa-inverse"
              />
            </span>
            <h4 className="my-3">E-Commerce</h4>
            <p className="text-muted">
              Disponemos de una aplicación de venta directa para la comercialización de nuestros productos y servicios, Tales como: kit Hidropónico, utensilios para el cultivo y servicios.
            </p>
          </Col>
          <Col md={4}>
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon
                icon={faCircle}
                className="fa-stack-2x circle"
              />
              <FontAwesomeIcon
                icon={faChartSimple} 
                className="fa-stack-1x fa-inverse"
              />
            </span>
            <h4 className="my-3">Análisis de datos</h4>
            <p className="text-muted">
              Contamos con tecnología de punta para ofrecer servicios de análisis de datos mediante inteligencia artificial e inteligencia de las cosas, Tales como redes neuronales y sensores en tiempo real.
            </p>
          </Col>
          <Col md={4}>
            <span className="fa-stack fa-4x">
              <FontAwesomeIcon
                icon={faCircle}
                className="fa-stack-2x circle"
              />
              <FontAwesomeIcon
                icon={faRobot}
                className="fa-stack-1x fa-inverse"
              />
            </span>
            <h4 className="my-3">Automatización de cultivos</h4>
            <p className="text-muted">
              Entregamos un servicio de automatización para todo cultivo ya sea en minoría o masivo, contando con tecnología de punta para maximizar el uso de agua.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Services;
