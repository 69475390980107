import React, { useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      // .post("http://localhost:5000/send", formData)
      .post("https://www.greenguardian.cl/send", formData) 
      .then((response) => {
        alert("Mensaje enviado exitosamente");
      })
      .catch((error) => {
        alert("Hubo un error al enviar el mensaje");
      });
  };

  return (
    <section className="page-section" id="contact">
      <Container>
        <div className="text-center">
          <h2 className="section-heading text-uppercase">Contáctenos</h2>
          <h3 className="section-subheading text-muted">
            ¡Estamos aquí para resolver sus dudas!
          </h3>
        </div>
        <Form id="contactForm" onSubmit={handleSubmit}>
          <Row className="align-items-stretch mb-5">
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Control
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Nombre *"
                  required
                  value={formData.name}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">El nombre es requerido.</div>
              </Form.Group>
              <Form.Group className="form-group">
                <Form.Control
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Correo electrónico *"
                  required
                  value={formData.email}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">El correo es requerido.</div>
                <div className="invalid-feedback">El correo no es válido.</div>
              </Form.Group>
              <Form.Group className="form-group mb-md-0">
                <Form.Control
                  id="phone"
                  name="phone"
                  type="tel"
                  placeholder="Teléfono *"
                  required
                  value={formData.phone}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">El teléfono es requerido.</div>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="form-group form-group-textarea mb-md-0">
                <Form.Control
                  as="textarea"
                  id="message"
                  name="message"
                  placeholder="Mensaje *"
                  required
                  value={formData.message}
                  onChange={handleChange}
                />
                <div className="invalid-feedback">El mensaje es requerido.</div>
              </Form.Group>
            </Col>
          </Row>
          <div className="text-center">
            <Button
              className="btn btn-contact btn-xl text-uppercase"
              id="submitButton"
              type="submit"
            >
              Enviar mensaje
            </Button>
          </div>
        </Form>
      </Container>
    </section>
  );
};

export default Contact;
