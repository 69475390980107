import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Footer = () => {
  return (
    <footer className="footer py-4">
      <Container>
        <Row className="align-items-center">
          <Col lg={4} className="text-lg-start">
            <p className="mb-0">
              <a href='https://www.google.com/maps/place/Universidad+T%C3%A9cnica+Federico+Santa+Mar%C3%ADa+(Sede+Concepci%C3%B3n)/@-36.7847959,-73.0876918,17z/data=!3m1!4b1!4m6!3m5!1s0x9669b562304e7983:0x85615e2a22fcf48!8m2!3d-36.7848002!4d-73.0851169!16s%2Fg%2F1v8329sm?entry=ttu' target='_blank'>
                <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
                Arteaga Alemparte 943, Hualpén, Bío Bío
              </a>
            </p>
            <p className="mb-0">
              <a href='mailto:contacto@greenguardian.cl' target='_blank'>
                <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                contacto@greenguardian.cl
              </a>
            </p>
          </Col>
          <Col lg={4} className="my-3 my-lg-0 text-center">
            <img src="assets/img/logos/small.png" alt="Logo" className="img-fluid small-logo" />
          </Col>
          <Col lg={4} className="text-lg-end">
            <a className="btn btn-dark btn-social mx-2" href="https://www.youtube.com/channel/UCxmtu5tAx58gxsz76jrs7cQ" target='_blank' aria-label="YouTube"><i className="fab fa-youtube"></i></a>
            <a className="btn btn-dark btn-social mx-2" href="https://www.instagram.com/greenguardiancl" target='_blank'   aria-label="Instagram"><i className="fab fa-instagram"></i></a>
            <a className="btn btn-dark btn-social mx-2" href="#!" target='_blank' aria-label="LinkedIn"><i className="fab fa-linkedin-in"></i></a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
